body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 50vh;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #19a582, #0a4d75);
  border-radius: 50vh;
  transition: all 300ms ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #0a4d75, #19a582);
}

::selection {
  color: #fff;
  background: #379237;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-wrap: auto;
}

.clamp-1 {
  -webkit-line-clamp: 1;
}

.clamp-2 {
  -webkit-line-clamp: 2;
}

.clamp-3 {
  -webkit-line-clamp: 3;
}

.clamp-4 {
  -webkit-line-clamp: 4;
}

.clamp-5 {
  -webkit-line-clamp: 5;
}

.nowarp {
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}


.textCenter{
  text-align: center;
}


.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap{
  flex-wrap: wrap;
}
.flexColumn{
  flex-flow: column;
}

.flexBetweenCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexCenterTop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flexCenterBottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flexTopCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexBottomCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.flexTopTop {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start
}

.flexStartCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexStartEnd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.flexBetweenTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flexStretchStart {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}
.flexStretchStart.flexColumn {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}