.st0 {
  opacity: 0.11;
  fill: #1d1d1b;
}

.st1 {
  fill: #c6d0d6;
}

.st2 {
  fill: #379237;
}

.st3 {
  fill: #3a3a3a;
}

.st4 {
  fill: #54b435;
}

.st5 {
  fill: #ffa87c;
}

.st6 {
  fill: #ffffff;
}

.st7 {
  fill: #fefefe;
}

.st8 {
  fill: #a34f2a;
}

.st9 {
  clip-path: url(#SVGID_00000018236904799670194720000005781985019504731522_);
}

.st10 {
  clip-path: url(#SVGID_00000110459877903164663280000005934891557097780406_);
}

.head {
  /*animation: swing 300ms ease infinite both;*/
}

@keyframes swing {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
